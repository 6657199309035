import React from 'react';
import './footer.scss';
import Vilanguage from '../../languages/vi';

const LanguageDisplay = Vilanguage;

export default class FooterView extends React.Component {
    render() {
        return (
            <footer className="footer-container">
                <div className="info">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="name">
                                    <img src={require('../../assets/image/logo.png')}/>
                                    <h3>Sở kế hoạch đầu tư <br/>Tỉnh Bắc Kạn</h3>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="detail">
                                    <p>
                                        <svg width="10" height="14" viewBox="0 0 10 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5 0C2.23571 0 0 2.191 0 4.9C0 8.575 5 14 5 14C5 14 10 8.575 10 4.9C10 2.191 7.76429 0 5 0ZM5 6.65C4.01429 6.65 3.21429 5.866 3.21429 4.9C3.21429 3.934 4.01429 3.15 5 3.15C5.98571 3.15 6.78571 3.934 6.78571 4.9C6.78571 5.866 5.98571 6.65 5 6.65Z"
                                                fill="#F5F5F5"/>
                                        </svg>
                                        Số 7 - đường Trường Chinh, phường Đức Xuân, thành phố Bắc Kạn.
                                    </p>
                                    <p>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0)">
                                                <path
                                                    d="M11.949 2.04951C9.21494 -0.683748 4.78276 -0.683091 2.04951 2.05101C-0.683748 4.78511 -0.683091 9.21726 2.05101 11.9505C4.78511 14.6838 9.21726 14.6831 11.9505 11.949C13.2632 10.6359 14.0004 8.85518 14 6.99851C13.9996 5.14214 13.2619 3.36195 11.949 2.04951ZM10.6013 9.75093C10.601 9.75123 10.6007 9.75156 10.6003 9.75186V9.74953L10.2457 10.1019C9.78697 10.5663 9.11893 10.7575 8.48398 10.6059C7.84428 10.4346 7.23615 10.1621 6.68266 9.79854C6.16843 9.46989 5.69189 9.08571 5.26166 8.65286C4.8658 8.2599 4.51017 7.82842 4.19998 7.36486C3.8607 6.86605 3.59216 6.32268 3.40198 5.75018C3.18397 5.07764 3.36463 4.33966 3.86866 3.84386L4.28398 3.42853C4.39946 3.31254 4.58709 3.31213 4.70305 3.4276C4.70335 3.42791 4.70368 3.42821 4.70398 3.42853L6.01531 4.73986C6.1313 4.85533 6.13171 5.04296 6.01624 5.15893C6.01594 5.15923 6.01564 5.15953 6.01531 5.15986L5.24531 5.92986C5.02437 6.14839 4.99659 6.49566 5.17998 6.74653C5.45848 7.12875 5.76667 7.4884 6.10166 7.82221C6.47515 8.19731 6.88117 8.53853 7.31498 8.84189C7.56564 9.01672 7.90547 8.98725 8.12231 8.77189L8.86663 8.01589C8.9821 7.89989 9.16974 7.89948 9.2857 8.01496C9.286 8.01526 9.2863 8.01556 9.28663 8.01589L10.6003 9.33189C10.7163 9.44733 10.7167 9.63494 10.6013 9.75093Z"
                                                    fill="#F5F5F5"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="14" height="14" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Điện thoại: 0209. 3870182 - Fax: 0209. 3870873
                                    </p>
                                    <p>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0)">
                                                <path
                                                    d="M6.31309 6.9277C6.67865 7.17142 7.32008 7.17145 7.68573 6.92767C7.68578 6.92765 7.68586 6.92759 7.68592 6.92757L13.9166 2.77371C13.7123 2.18264 13.1505 1.75684 12.4909 1.75684H1.50783C0.848154 1.75684 0.286427 2.18264 0.0820312 2.77371L6.3129 6.92759C6.31298 6.92765 6.31303 6.92765 6.31309 6.9277Z"
                                                    fill="#F5F5F5"/>
                                                <path
                                                    d="M8.14155 7.61073C8.14147 7.61078 8.14141 7.61084 8.14136 7.61086C7.82138 7.82417 7.41062 7.93084 6.99999 7.93084C6.58929 7.93084 6.17869 7.8242 5.85869 7.61084C5.85863 7.61081 5.8586 7.61078 5.85855 7.61075L0 3.70508V10.7344C0 11.5662 0.676681 12.2428 1.50846 12.2428H12.4916C13.3233 12.2428 14 11.5662 14 10.7344V3.70508L8.14155 7.61073Z"
                                                    fill="#F5F5F5"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="14" height="14" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Email: soxaydung@backan.gov.vn
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        © Bản quyền thuộc về Sở Kế Hoạch Đầu Tư Tỉnh Bắc Kạn
                    </div>
                </div>
            </footer>
        )
    }
}